import React, { useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { useGetFaqsQuery } from "../features/manage-property/api/propertyApi";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const { data: FAQs, isLoading } = useGetFaqsQuery();

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="py-12 bg-white">
      <div className="container px-4">
        <h2 className="text-center text-2xl font-bold mb-6">
          Frequently Asked Questions (FAQs)
        </h2>
        <div className="w-full space-y-4">
          {isLoading
            ? Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  className="border rounded-lg overflow-hidden animate-pulse"
                >
                  <div className="w-full px-6 py-4 bg-gray-200 border-b flex justify-between items-center">
                    <div className="h-4 w-3/4 bg-gray-300 rounded"></div>
                    <div className="h-4 w-6 bg-gray-300 rounded"></div>
                  </div>
                  <div className="px-6 py-4 bg-gray-100">
                    <div className="h-3 w-full bg-gray-300 rounded mb-2"></div>
                    <div className="h-3 w-5/6 bg-gray-300 rounded mb-2"></div>
                    <div className="h-3 w-4/6 bg-gray-300 rounded"></div>
                  </div>
                </div>
              ))
            : FAQs?.data.map((faq, index) => (
                <div key={index} className="border rounded-lg overflow-hidden">
                  <button
                    className="w-full text-left px-6 py-4 bg-white border-b flex justify-between items-center"
                    onClick={() => toggleFAQ(index)}
                  >
                    <span className="font-medium">{faq.title}</span>
                    <span className="text-lg font-extrabold ">
                      {openIndex === index ? (
                        <AiOutlineUp />
                      ) : (
                        <AiOutlineDown />
                      )}
                    </span>
                  </button>
                  {openIndex === index && (
                    <div className="px-6 py-4 bg-gray-50">{faq.detail}</div>
                  )}
                </div>
              ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
