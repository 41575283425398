import React, { useState } from "react";
import { useSignupMutation } from "../api/authApi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTokenAfterSignup } from "../slices/authSlice";

const SignUp = () => {
  const dispatch = useDispatch();
  const [signup, { isLoading }] = useSignupMutation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      const response = await signup(formData).unwrap();
      console.log("Signup successful:", response);
      dispatch(setTokenAfterSignup(response));
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Failed to sign up:", error);
    }
  };

  return (
    <div className="grid grid-rows-[auto,1fr,auto] min-h-screen bg-gray-100">
      {/* Header placeholder */}
      <header className="bg-gray-800 text-white p-4 text-center mt-4"></header>

      {/* Main content */}
      <main className="flex items-center justify-center p-4">
        <div className="bg-white p-8 rounded-lg w-full max-w-lg shadow-md">
          <h2 className="text-2xl font-bold text-center mb-6">Sign up</h2>
          <form
            className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2"
            onSubmit={handleSubmit}
          >
            <div>
              <label className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                className="mt-1 block w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your first name"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                className="mt-1 block w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your last name"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="sm:col-span-2">
              <label className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                type="text"
                name="phoneNumber"
                className="mt-1 block w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your phone"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </div>
            <div className="sm:col-span-2">
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                name="email"
                className="mt-1 block w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                className="mt-1 block w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 pr-10"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleChange}
              />
              <span
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-3 mt-3 flex items-center cursor-pointer text-gray-500"
                style={{ top: "50%", transform: "translateY(-50%)" }} // Center the icon vertically
              >
                {showPassword ? "👁️" : "🙈"}
              </span>
            </div>
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700">
                Confirm Password
              </label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                className="mt-1 block w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 pr-10"
                placeholder="Confirm your password"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              <span
                onClick={toggleConfirmPasswordVisibility}
                className="absolute inset-y-0 right-3 mt-2 flex items-center cursor-pointer text-gray-500"
                style={{ top: "50%", transform: "translateY(-50%)" }} // Center the icon vertically
              >
                {showConfirmPassword ? "👁️" : "🙈"}
              </span>
            </div>
            <div className="sm:col-span-2">
              <button
                type="submit"
                className="w-full p-3 bg-black text-white rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={isLoading}
              >
                {isLoading ? "Signing up..." : "Signup"}
              </button>
            </div>
          </form>
          <div className="mt-4 text-center">
            <p className="text-sm text-gray-600">
              Have an account?{" "}
              <Link to="/signin" className="text-red-400 hover:underline">
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SignUp;
