import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { FaRegUser, FaRegHeart, FaBell } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectFavoriteCount } from "../features/manage-property/slice/propertySlice";
import { selectIsAuthenticated } from "../features/authentication/slices/authSlice";
import logo from "../assets/logo1.png";
import {
  useGetNotificationsQuery,
  useCheckNotificationMutation,
} from "../features/manage-property/api/propertyApi";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [unseenNotifications, setUnseenNotifications] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const favoriteCount = useSelector(selectFavoriteCount);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const heartIconRef = useRef(null);
  const dropdownRef = useRef(null);

  const {
    data: notifications,
    isLoading,
    isError,
  } = useGetNotificationsQuery();
  const [checkNotification] = useCheckNotificationMutation();

  useEffect(() => {
    if (notifications) {
      const unseen = notifications.data.filter((n) => !n.checked);
      setUnseenNotifications(unseen);
    }
  }, [notifications]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 200;
      setIsScrolled(scrollPosition > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNotificationClick = async (notification) => {
    try {
      await checkNotification(notification._id).unwrap();
      setUnseenNotifications((prev) =>
        prev.filter((n) => n._id !== notification._id)
      );
    } catch (error) {
      console.error("Failed to check notification:", error);
    }

    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <header
      className={`bg-white shadow-md fixed top-0 left-0 right-0 z-50 transition-transform duration-300 ${
        isScrolled ? "shadow-lg" : "shadow-md"
      }`}
    >
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <div className="flex items-center space-x-6">
          <Link to="/" className="md:block">
            <img src={logo} alt="Logo" className="h-10" />
          </Link>
          <nav className="hidden md:flex space-x-8 text-base font-medium">
            <Link
              to="/for-sale"
              className="text-gray-700 hover:text-gray-900 transition duration-200"
            >
              For Sale
            </Link>
            <Link
              to="/for-rent"
              className="text-gray-700 hover:text-gray-900 transition duration-200"
            >
              For Rent
            </Link>
            <Link
              to="/my-list"
              className="text-gray-700 hover:text-gray-900 transition duration-200"
            >
              My List
            </Link>
          </nav>
        </div>
        <div className="flex space-x-6 items-center">
          <div className="relative hidden md:block" ref={heartIconRef}>
            <Link to="/saved-properties">
              <FaRegHeart className="h-6 w-6 text-gray-700 hover:text-red-500 transition duration-200 cursor-pointer" />
            </Link>
            {favoriteCount > 0 && (
              <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full">
                {favoriteCount}
              </span>
            )}
          </div>

          {isAuthenticated && (
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={() => setIsDropdownOpen((prev) => !prev)}
                className="relative"
              >
                <FaBell className="h-6 w-6 text-gray-700 hover:text-gray-900 transition duration-200 cursor-pointer" />
                {unseenNotifications.length > 0 && (
                  <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full">
                    {unseenNotifications.length}
                  </span>
                )}
              </button>

              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-64 bg-white shadow-lg rounded-lg overflow-hidden z-50">
                  <div className="p-4 border-b">
                    <h4 className="font-semibold text-gray-800">
                      Notifications
                    </h4>
                  </div>
                  <div className="p-4 space-y-4 max-h-48 overflow-y-auto">
                    {isLoading ? (
                      <p className="text-sm text-gray-600">Loading...</p>
                    ) : isError ? (
                      <p className="text-sm text-red-500">
                        Failed to load notifications
                      </p>
                    ) : unseenNotifications.length === 0 ? (
                      <p className="text-sm text-gray-600">
                        No new notifications
                      </p>
                    ) : (
                      unseenNotifications.map((notification) => (
                        <div
                          key={notification._id}
                          onClick={() => handleNotificationClick(notification)}
                          className="cursor-pointer"
                        >
                          <p className="font-semibold text-gray-800">
                            {notification.title}
                          </p>
                          <p className="text-sm text-gray-600 mt-1">
                            {notification.message}
                          </p>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {isAuthenticated ? (
            <Link to="/profile">
              <FaRegUser className="h-6 w-6 text-gray-700 hover:text-gray-900 transition duration-200 hidden md:block" />
            </Link>
          ) : (
            <Link to="/signin" className="hidden md:block">
              <button className="text-gray-700 text-base font-semibold hover:text-gray-900 transition duration-200">
                Sign in
              </button>
            </Link>
          )}

          {/* Show Post button on small screens as well */}
          {/* {!isAuthenticated ? (
            <Link
              to="/add-property"
              className="bg-black text-white font-semibold px-4 py-2 rounded-lg shadow transition duration-200"
            >
              Post
            </Link>
          ) : (
            <Link
              to="/add-more"
              className="bg-black text-white font-semibold px-4 py-2 rounded-lg shadow transition duration-200"
            >
              Post
            </Link>
          )} */}
          <Link
            to="/post"
            className="bg-black text-white font-semibold px-4 py-2 rounded-lg shadow transition duration-200"
          >
            Post
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
