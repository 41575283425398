import React, { useState } from "react";
import { FaSearch, FaHeart, FaRegUser, FaHome } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectFavoriteCount } from "../features/manage-property/slice/propertySlice";
import { Link } from "react-router-dom";

const MobileBottomNav = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const favoriteCount = useSelector(selectFavoriteCount);

  const navItems = [
    { icon: FaHome, to: "/" },
    { icon: FaSearch, to: "/" },
    { icon: FaHeart, to: "/saved-properties", hasBadge: true }, 
    { icon: FaRegUser, to: "/profile" }, 
  ];

  return (
    <nav className="fixed bottom-4 left-6 right-6 bg-black rounded-2xl p-2 md:hidden flex justify-around items-center shadow-lg border-t border-gray-800">
      {navItems.map((item, index) => {
        const Icon = item.icon;
        return (
          <Link
            key={index}
            to={item.to}
            onClick={() => setActiveIndex(index)}
            className={`relative flex justify-center items-center p-2 ${
              activeIndex === index ? "text-red-200" : "text-white"
            }`}
          >
            <Icon size={24} />
            {item.hasBadge && favoriteCount > 0 && (
              <span className="absolute -top-0 -right-1 bg-red-500 text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full">
                {favoriteCount}
              </span>
            )}
          </Link>
        );
      })}
    </nav>
  );
};

export default MobileBottomNav;
