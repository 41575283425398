"use client";

import { useState } from "react";
import { motion } from "framer-motion";
import PropertyDetailsStep from "../components/PropertyDetailsStep";
import HomeTypeStep from "../components/HomeTypeStep";
import HomeFacilitiesStep from "../components/HomeFacilitiesStep";
import SignupModal from "../../authentication/pages/SignupModal";
import { useAddPropertyMutation } from "../api/propertyApi";
import { useSignupMutation } from "../../authentication/api/authApi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectOwnerId, setToken } from "../../authentication/slices/authSlice";
import NewLocationStep from "./NewLocationStep";

const steps = ["Type", "Facilities", "Details", "Location"];

const ProgressBar = ({ currentStep, totalSteps, onStepClick }) => (
  <div className="w-full mb-8">
    <div className="flex justify-between mb-2">
      {steps.map((stepName, index) => (
        <div
          key={index}
          className="flex flex-col items-center cursor-pointer"
          onClick={() => onStepClick(index + 1)}
        >
          <div
            className={`w-8 h-8 rounded-full flex items-center justify-center text-sm font-semibold transition-colors duration-300 ${
              index + 1 <= currentStep
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-600 hover:bg-blue-400 hover:text-white"
            }`}
          >
            {index + 1}
          </div>
          <span className="mt-1 text-xs text-gray-600">{stepName}</span>
        </div>
      ))}
    </div>
    <div className="relative h-2 bg-gray-200 rounded-full">
      <motion.div
        className="absolute h-full bg-blue-600 rounded-full"
        initial={{ width: 0 }}
        animate={{ width: `${((currentStep - 1) / (totalSteps - 1)) * 100}%` }}
        transition={{ duration: 0.3 }}
      />
    </div>
  </div>
);

const AddProperty = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ownerId = useSelector(selectOwnerId);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    title: "",
    type: "",
    city: "",
    areas: "",
    propertyStatus: "",
    price: "",
    description: "",
    images: [],
    address: "",
    absoluteLocation: { type: "Point", coordinates: [0, 0], address: "", description: "" },
    relativeLocation: { country: "", city: "", extraDetail: "" },
    owner: ownerId,
    bedrooms: 0,
    bathrooms: 0,
    unitNumber: "",
    floorNumber: 0,
    footTraffic: 0,
    displayWindows: false,
    leaseTerms: "",
    parkingSpaces: 0,
    buildingAmenities: [],
  });

  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [signup] = useSignupMutation();
  const [addProperty] = useAddPropertyMutation();

  const nextStep = () => setStep((prev) => Math.min(prev + 1, 4));
  const prevStep = () => setStep((prev) => Math.max(prev - 1, 1));

  const handleStepClick = (clickedStep) => {
    if (clickedStep <= step + 1) {
      setStep(clickedStep);
    }
  };

  const handleSignup = async (signupData) => {
    try {
      const signupRes = await signup(signupData).unwrap();
      dispatch(setToken(signupRes));
      const userId = signupRes.data.newOwner._id;
      setIsSignupModalOpen(false);
      const propertyData = { ...formData, owner: userId };

      const addProp = await addProperty(propertyData).unwrap();
      console.log("Property added successfully:", addProp);
      navigate("/");
      window.location.reload();
    } catch (err) {
      console.error("Signup or Property addition failed:", err);
    }
  };

  const handleSignupModalClose = () => setIsSignupModalOpen(false);

  const renderStep = () => {
    switch (step) {
      case 1:
        return <HomeTypeStep nextStep={nextStep} formData={formData} setFormData={setFormData} />;
      case 2:
        return <HomeFacilitiesStep nextStep={nextStep} prevStep={prevStep} formData={formData} setFormData={setFormData} />;
      case 3:
        return <PropertyDetailsStep nextStep={nextStep} prevStep={prevStep} formData={formData} setFormData={setFormData} />;
      case 4:
        return (
          <>
            <NewLocationStep prevStep={prevStep} formData={formData} setFormData={setFormData} openModal={() => setIsSignupModalOpen(true)} />
            <SignupModal isOpen={isSignupModalOpen} onClose={handleSignupModalClose} onSubmit={handleSignup} />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col items-center">
      <ProgressBar currentStep={step} totalSteps={4} onStepClick={handleStepClick} />
      {renderStep()}
    </div>
  );
};

export default AddProperty;
