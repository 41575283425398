import React from "react";
import { motion } from "framer-motion";

const HomeFacilitiesStep = ({ nextStep, prevStep, formData, setFormData }) => {
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleAmenitiesChange = (e) => {
    const { value, checked } = e.target;
    const { buildingAmenities = [] } = formData;

    if (checked) {
      setFormData({
        ...formData,
        buildingAmenities: [...buildingAmenities, value],
      });
    } else {
      setFormData({
        ...formData,
        buildingAmenities: buildingAmenities.filter(
          (amenity) => amenity !== value
        ),
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-white px-4">
      <motion.div
        className="py-6 px-8 w-full max-w-5xl bg-white rounded-lg"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-semibold mb-6 text-gray-800 text-center">
          Home Facilities
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          <div>
            <label htmlFor="bedrooms" className="block text-gray-700 mb-2">
              Bedrooms
            </label>
            <input
              type="number"
              id="bedrooms"
              name="bedrooms"
              value={formData.bedrooms || ""}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>

          <div>
            <label htmlFor="bathrooms" className="block text-gray-700 mb-2">
              Bathrooms
            </label>
            <input
              type="number"
              id="bathrooms"
              name="bathrooms"
              value={formData.bathrooms || ""}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>

          <div>
            <label htmlFor="unitNumber" className="block text-gray-700 mb-2">
              Unit Number
            </label>
            <input
              type="text"
              id="unitNumber"
              name="unitNumber"
              value={formData.unitNumber || ""}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>

          <div>
            <label htmlFor="floorNumber" className="block text-gray-700 mb-2">
              Floor Number
            </label>
            <input
              type="number"
              id="floorNumber"
              name="floorNumber"
              value={formData.floorNumber || ""}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>

          <div>
            <label htmlFor="footTraffic" className="block text-gray-700 mb-2">
              Foot Traffic
            </label>
            <input
              type="number"
              id="footTraffic"
              name="footTraffic"
              value={formData.footTraffic || ""}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>

          <div>
            <label htmlFor="leaseTerms" className="block text-gray-700 mb-2">
              Lease Terms
            </label>
            <input
              type="text"
              id="leaseTerms"
              name="leaseTerms"
              value={formData.leaseTerms || ""}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>

          <div>
            <label htmlFor="parkingSpaces" className="block text-gray-700 mb-2">
              Parking Spaces
            </label>
            <input
              type="number"
              id="parkingSpaces"
              name="parkingSpaces"
              value={formData.parkingSpaces || ""}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>

          <div className="col-span-1 md:col-span-2 lg:col-span-3">
            <label className="block text-gray-700 mb-2">
              Building Amenities
            </label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {["Gym", "Swimming Pool", "24/7 Security", "Parking"].map(
                (amenity) => (
                  <label key={amenity} className="flex items-center space-x-3">
                    <input
                      type="checkbox"
                      name="buildingAmenities"
                      value={amenity}
                      checked={
                        formData.buildingAmenities?.includes(amenity) || false
                      }
                      onChange={handleAmenitiesChange}
                      className="form-checkbox h-5 w-5 text-black focus:ring-black"
                    />
                    <span className="text-gray-700">{amenity}</span>
                  </label>
                )
              )}
            </div>
          </div>
        </div>

        <div className="mt-8 flex justify-between">
          <motion.button
            onClick={prevStep}
            className="bg-gray-600 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.3 }}
          >
            Back
          </motion.button>

          <motion.button
            onClick={nextStep}
            className="bg-black text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ease-in-out"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.3 }}
          >
            Next
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
};

export default HomeFacilitiesStep;
