import React from "react";
import { useParams } from "react-router-dom";
import PropertyListings from "../../../components/PropertyListings";
import emptyIllustration from "../../../assets/no-home.jpg";
import { useFilterPropertiesByCityQuery } from "../api/propertyApi";

const PropertyInCity = () => {
  const { city } = useParams();
  // console.log("Requested city:", city);

  const {
    data: response,
    isLoading,
    error,
  } = useFilterPropertiesByCityQuery(city, {
    refetchOnWindowFocus: true,
  });

  // console.log("API response:", response);

  const properties =
    response?.data?.map((property) => ({
      id: property._id,
      image: property.image[0],
      status: `New • ${new Date(property.createdAt).toLocaleTimeString()}`,
      price: property.price.toLocaleString(),
      bed: property.bedrooms || 0,
      bath: property.bathrooms || 0,
      city: property.city,
      sqft: property.size || "N/A",
      type: property.type,
    })) || [];

  const renderEmptyState = () => (
    <div className="flex flex-col items-center mt-8">
      <img
        src={emptyIllustration}
        alt="No properties available"
        className="w-1/5 mb-8"
      />
      <p className="text-2xl text-gray-600 text-center">
        No properties found for "{city}".
      </p>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-12">
      <h2 className="text-3xl py-8 mb-[-48px] font-semibold text-center text-gray-800">
        Property in {city}
      </h2>

      {isLoading ? (
        <div className="flex justify-center items-center px-6 py-12">
          <div className="text-center">
            <svg
              className="animate-spin h-10 w-10 text-red-500 mx-auto mb-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
            <p className="text-xl text-gray-600">Loading properties...</p>
          </div>
        </div>
      ) : error ? (
        <div className="text-center text-red-500">
          Error fetching properties.
        </div>
      ) : properties.length === 0 ? (
        renderEmptyState()
      ) : (
        <PropertyListings properties={properties} />
      )}
    </div>
  );
};

export default PropertyInCity;
