import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiFilter } from "react-icons/fi"; // Import filter icon
import FilterModal from "./FilterModal";
import { useGetCitiesQuery } from "../features/manage-property/api/propertyApi";

const Hero = () => {
  const [city, setCity] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const navigate = useNavigate();

  // Fetch city data using the query
  const { data: cities, isLoading } = useGetCitiesQuery();
  // console.log("ciiesss", cities?.data);

  const handleSearch = () => {
    if (city.trim()) {
      navigate(`/properties/city/${encodeURIComponent(city)}`);
    } else {
      alert("Please enter a city to search for properties.");
    }
  };

  const [showFilterModal, setShowFilterModal] = useState(false);

  const toggleFilterModal = () => {
    setShowFilterModal((prev) => !prev);
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleSuggestionClick = (suggestion) => {
    setCity(suggestion);
    setShowSuggestions(false);
    navigate(`/properties/city/${encodeURIComponent(suggestion)}`);
  };

  return (
    <section
      className="bg-cover bg-center h-screen flex flex-col items-center justify-center relative"
      style={{
        backgroundImage: `url(https://media.istockphoto.com/id/1481643878/photo/american-style-country-house-on-a-striking-summer-day-with-blue-sky-beautiful-residential.jpg?s=612x612&w=0&k=20&c=xDBSiWJewpbwg1pFFTITNjEAKBiARrIf9sav9gCCnpg=)`,
      }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black opacity-60"></div>

      <div className="relative text-center z-10 px-4">
        <h1 className="text-white text-2xl sm:text-3xl md:text-5xl font-bold mb-6 md:mb-10">
          <span className="text-red-500 text-4xl sm:text-5xl md:text-6xl font-extrabold">
            ቤት
          </span>
          Ale : Site for Rent and Sale Home
        </h1>

        <div className="bg-white flex flex-col items-center rounded-lg px-2 py-2 w-full sm:w-4/5 md:max-w-lg mx-auto transition-transform duration-300">
          <div className="flex items-center w-full">
            <input
              type="text"
              placeholder="Search property in city you want"
              className="w-full text-sm md:text-base outline-none text-gray-700 px-2 py-1 sm:px-4 sm:py-2"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
                setShowSuggestions(true);
              }}
            />
            <button
              onClick={handleSearch}
              className="bg-black text-white text-sm md:text-base px-4 sm:px-6 py-2 rounded-full"
            >
              Search
            </button>
          </div>

          {showSuggestions && city.trim() && (
            <ul className="w-full bg-white border border-gray-200 rounded-md shadow-md mt-2 max-h-40 overflow-auto">
              {isLoading ? (
                <li className="px-4 py-2 text-gray-500">Loading...</li>
              ) : cities?.data?.length ? (
                cities.data
                  .filter(
                    (c) => c.name.toLowerCase().includes(city.toLowerCase()) // Match any part of the city name
                  )
                  .map((suggestion, index) => (
                    <li
                      key={index}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleSuggestionClick(suggestion.name)} // Use `suggestion.name`
                    >
                      {suggestion.name}
                    </li>
                  ))
              ) : (
                <li className="px-4 py-2 text-gray-500">No cities found</li>
              )}
            </ul>
          )}
        </div>
      </div>

      <div className="mt-6 z-10" id="hero-links">
        <div className="flex justify-center items-center space-x-4">
          <button
            onClick={() => handleNavigate("/for-rent")}
            className="font-semibold text-white underline text-lg sm:text-xl px-4 py-2 sm:py-4 active:underline"
          >
            For Rent
          </button>
          <button
            onClick={() => handleNavigate("/for-sale")}
            className="font-semibold text-white underline text-lg sm:text-xl px-4 py-2 sm:py-4 active:underline"
          >
            For Sale
          </button>
          <div className="relative">
            <button
              onClick={toggleFilterModal}
              className="font-semibold flex items-center text-white underline text-lg sm:text-xl px-4 py-2 sm:py-4"
            >
              <FiFilter size={24} />
              <span>Filter</span>
            </button>
            {showFilterModal && <FilterModal onClose={toggleFilterModal} />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
