import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useGetCitiesQuery, useUpdatePropertyMutation } from "../api/propertyApi";
import { X } from "lucide-react";
import InputField from "../components/InputField";
import SelectField from "../components/SelectField";
import Checkbox from "../components/Checkbox";
import RadioButton from "../components/RadioButton";

const amenitiesList = ["Gym", "Swimming Pool", "24/7 Security", "Parking"];

const EditModal = ({ isOpen, onClose, property }) => {
  console.log("prop:", property);

  const dispatch = useDispatch();
  const [updateProperty] = useUpdatePropertyMutation();
  const { data: cities, isLoading, isError } = useGetCitiesQuery();

  const [areas, setAreas] = useState([]);

  const [formData, setFormData] = useState({
    _id: property.id,
    title: property.title || "",
    bed: property.bed || 0,
    bath: property.bath || 0,
    unitNumber: property.unitNumber || "",
    floorNumber: property.floorNumber || "",
    footTraffic: property.footTraffic || "",
    leaseTerms: property.leaseTerms || "",
    parkingSpaces: property.parkingSpaces || 0,
    price: property.price || "",
    city: property.city || "",
    area: property.area || "",
    propertyStatus: property.propertyStatus || "",
    images: property.image || [],
    type: property.type || "",
    amenities: property.amenities || [],
  });
  console.log("dtaaa", formData);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        amenities: checked
          ? [...prev.amenities, value]
          : prev.amenities.filter((amenity) => amenity !== value),
      }));
    } else if (type === "file") {
      const files = Array.from(e.target.files);
      setFormData((prev) => ({
        ...prev,
        images: [...prev.images, ...files],
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };
  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setFormData({ ...formData, city: selectedCity, area: "" });

    const cityData = cities?.data?.find((city) => city.name === selectedCity);
    setAreas(cityData?.areas || []);
  };

  const handleAreaChange = (e) => {
    setFormData({ ...formData, area: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedPrice = formData.price.toString().replace(/,/g, "");

    const dataToSubmit = {
      ...formData,
      price: parseFloat(formattedPrice),
    };
    const res = await updateProperty(dataToSubmit).unwrap();
    console.log("res", res);
    onClose();
    window.location.reload();
  };

  const handleImageRemove = (index) => {
    setFormData((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-100 rounded-lg shadow-lg w-full max-w-lg sm:max-w-md lg:max-w-xl xl:max-w-2xl p-6 overflow-y-auto relative max-h-[80vh]">
        {/* Header */}
        <div className="flex justify-between items-center border-b pb-3 mb-4">
          <h2 className="text-lg font-bold">Edit Your Property</h2>
          <button
            className="text-gray-500 hover:text-red-500 transition-colors"
            onClick={onClose}
          >
            <X />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid gap-2">
            <label className="text-sm font-semibold">Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="w-full border-gray-300 rounded-md shadow-sm p-2 focus:ring focus:ring-blue-300"
              placeholder="Property Name"
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <InputField
              label="Bedrooms"
              name="bed"
              value={formData.bed}
              onChange={handleChange}
              placeholder="No. of Bedrooms"
              type="number"
            />
            <InputField
              label="Bathrooms"
              name="bath"
              value={formData.bath}
              onChange={handleChange}
              placeholder="No. of Bathrooms"
              type="number"
            />
          </div>

          <SelectField
            label="Property Type"
            name="type"
            value={formData.type}
            onChange={handleChange}
            options={["Apartment", "House", "Condo", "Office", "Commercial"]}
          />

          <div className="grid gap-2">
            <label className="text-sm font-semibold">Amenities</label>
            <div className="space-y-2">
              {amenitiesList.map((amenity, index) => (
                <Checkbox
                  key={index}
                  label={amenity}
                  name="amenities"
                  value={amenity}
                  checked={formData.amenities.includes(amenity)}
                  onChange={handleChange}
                />
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <InputField
              label="Price"
              name="price"
              value={formData.price}
              onChange={handleChange}
              placeholder="Price"
              type="number"
            />
            <InputField
              label="Parking Spaces"
              name="parkingSpaces"
              value={formData.parkingSpaces}
              onChange={handleChange}
              placeholder="No. of Parking Spaces"
              type="number"
            />
          </div>

          <div className="grid gap-2">
            <label className="text-sm font-semibold">City</label>
            <select
              name="city"
              value={formData.city}
              onChange={handleCityChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="" disabled>
                {isLoading ? "Loading cities..." : "Select a city"}
              </option>
              {isError ? (
                <option disabled>
                  Error loading cities. Please try again.
                </option>
              ) : (
                cities?.data?.map((city) => (
                  <option key={city._id} value={city.name}>
                    {city.name}
                  </option>
                ))
              )}
            </select>
          </div>
          <div className="grid gap-2">
            <label className="text-sm font-semibold">Area</label>
            <select
              name="area"
              value={formData.area}
              onChange={handleAreaChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              disabled={!areas.length}
            >
              <option value="" disabled>
                Select an area
              </option>
              {areas.map((area, index) => (
                <option key={index} value={area}>
                  {area}
                </option>
              ))}
            </select>
          </div>

          <div className="grid gap-2">
            <label className="text-sm font-semibold">Status</label>
            <div className="flex flex-wrap gap-4">
              <RadioButton
                label="For Sale"
                name="propertyStatus"
                value="For Sale"
                checked={formData.propertyStatus === "For Sale"}
                onChange={handleChange}
              />
              <RadioButton
                label="For Rent"
                name="propertyStatus"
                value="For Rent"
                checked={formData.propertyStatus === "For Rent"}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="grid gap-2">
            <label className="text-sm font-semibold">Images</label>
            <input
              type="file"
              name="images"
              multiple
              onChange={handleChange}
              className="border-gray-300 rounded-md shadow-sm p-2 focus:ring focus:ring-blue-300"
            />
            <div className="flex space-x-2 mt-2">
              {formData.images.map((image, index) =>
                image instanceof File ? (
                  <div key={index} className="relative">
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`property-image-${index}`}
                      className="h-20 w-20 object-cover rounded-md"
                    />
                    <button
                      type="button"
                      onClick={() => handleImageRemove(index)}
                      className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                    >
                      <X size={12} />
                    </button>
                  </div>
                ) : null
              )}
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md shadow hover:bg-gray-400 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-black text-white rounded-md shadow transition-colors"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditModal;
