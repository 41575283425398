import React from "react";
import { motion } from "framer-motion";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

const HomeTypeStep = ({ nextStep, formData, setFormData }) => {
  const homeTypes = [
    "Apartment",
    "Condo",
    "Land",
    "House",
    "Office & Shop",
    "Residence Villa",
    "Real Estate",
    "Commercial Area",
    "Commercial Building",
    "Warehouse",
    "Factory/Industry",
    "Guest House",
    "Other",
  ];

  const handleTitleChange = (e) => {
    setFormData({ ...formData, title: e.target.value });
  };

  const handleHomeTypeChange = (e) => {
    setFormData({ ...formData, type: e.target.value });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-white px-4">
      <div className="py-4 px-8 w-full max-w-3xl bg-white rounded-lg">
        <Link
          to="/"
          className="flex items-center text-gray-700 mb-6 hover:text-black"
        >
          <FaArrowLeft className="text-xl mr-2" />
        </Link>
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="mb-6">
            <label htmlFor="title" className="block text-gray-700 mb-2">
              Property Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleTitleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter title"
            />
          </div>

          <h3 className="text-lg font-semibold mb-4">
            Select the Type of Property
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {homeTypes.map((type) => (
              <label key={type} className="flex items-center space-x-3">
                <input
                  type="radio"
                  name="homeType"
                  value={type}
                  checked={formData.type === type}
                  onChange={handleHomeTypeChange}
                  className="form-radio h-5 w-5 text-black focus:ring-black"
                />
                <span className="text-gray-700">{type}</span>
              </label>
            ))}
          </div>
        </motion.div>

        <motion.div
          className="mt-8 flex justify-end items-center space-x-4"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <button
            onClick={nextStep}
            className="bg-black text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
          >
            Next
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default HomeTypeStep;
