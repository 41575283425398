"use client";

import { useState } from "react";
import { motion } from "framer-motion";
import PropertyDetailsStep from "../components/PropertyDetailsStep";
import HomeTypeStep from "../components/HomeTypeStep";
import HomeFacilitiesStep from "../components/HomeFacilitiesStep";
import { useAddPropertyMutation } from "../api/propertyApi";
import { useNavigate } from "react-router-dom";
import { selectOwnerId } from "../../authentication/slices/authSlice";
import { useSelector } from "react-redux";
import NewLocationStep from "./NewLocationStep";

const steps = ["Type", "Facilities", "Details", "Location"];

const ProgressBar = ({ currentStep, totalSteps, onStepClick }) => {
  return (
    <div className="w-full mb-8">
      <div className="flex justify-between mb-2">
        {steps.map((stepName, index) => (
          <div
            key={index}
            className="flex flex-col items-center cursor-pointer"
            onClick={() => onStepClick(index + 1)}
          >
            <div
              className={`w-8 h-8 rounded-full flex items-center justify-center text-sm font-semibold transition-colors duration-300 ${
                index + 1 <= currentStep
                  ? "bg-blue-600 text-white"
                  : "bg-gray-200 text-gray-600 hover:bg-blue-400 hover:text-white"
              }`}
            >
              {index + 1}
            </div>
            <span className="mt-1 text-xs text-gray-600">{stepName}</span>
          </div>
        ))}
      </div>
      <div className="relative h-2 bg-gray-200 rounded-full">
        <motion.div
          className="absolute h-full bg-blue-600 rounded-full"
          initial={{ width: 0 }}
          animate={{
            width: `${((currentStep - 1) / (totalSteps - 1)) * 100}%`,
          }}
          transition={{ duration: 0.3 }}
        />
      </div>
    </div>
  );
};

const AddPropertyForSignedUser = () => {
  const navigate = useNavigate();
  const ownerId = useSelector(selectOwnerId);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    title: "",
    type: "",
    city: "",
    areas: "",
    propertyStatus: "",
    price: "",
    description: "",
    images: [],
    address: "",
    absoluteLocation: {
      type: "Point",
      coordinates: [0, 0],
      address: "",
      description: "",
    },
    relativeLocation: {
      country: "",
      city: "",
      extraDetail: "",
    },
    owner: ownerId,
    bedrooms: 0,
    bathrooms: 0,
    unitNumber: "",
    floorNumber: 0,
    footTraffic: 0,
    displayWindows: false,
    leaseTerms: "",
    parkingSpaces: 0,
    buildingAmenities: [],
  });

  const [addProperty] = useAddPropertyMutation();

  const nextStep = () => setStep((prev) => Math.min(prev + 1, 4));
  const prevStep = () => setStep((prev) => Math.max(prev - 1, 1));

  const handleStepClick = (clickedStep) => {
    // Only allow going to steps that have been completed or are next
    if (clickedStep <= step + 1) {
      setStep(clickedStep);
    }
  };

  const handleSubmitProperty = async () => {
    try {
      const addProp = await addProperty(formData).unwrap();
      console.log("Property added successfully:", addProp);
      navigate("/");
      window.location.reload();
    } catch (err) {
      console.error("Property addition failed:", err);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <HomeTypeStep
            nextStep={nextStep}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 2:
        return (
          <HomeFacilitiesStep
            nextStep={nextStep}
            prevStep={prevStep}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 3:
        return (
          <PropertyDetailsStep
            nextStep={nextStep}
            prevStep={prevStep}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 4:
        return (
          <NewLocationStep
            prevStep={prevStep}
            formData={formData}
            setFormData={setFormData}
            onSubmit={handleSubmitProperty}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-white py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <ProgressBar
          currentStep={step}
          totalSteps={4}
          onStepClick={handleStepClick}
        />
        <motion.div
          className="bg-white rounded-lg overflow-hidden"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="p-6">
            <motion.div
              key={step}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
            >
              {renderStep()}
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AddPropertyForSignedUser;
