import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Heart, Edit } from "lucide-react"; // Importing icons from Lucide React
import { MdLocationOn } from "react-icons/md"; // Importing location icon
import {
  selectFavorites,
  toggleFavorite,
  removeFavorite,
} from "../features/manage-property/slice/propertySlice";
import EditModal from "../features/manage-property/pages/EditModal";
import { selectIsAuthenticated } from "../features/authentication/slices/authSlice";

const PropertyCard = ({ property, isEditable, showFavorite = false }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const favorites = useSelector(selectFavorites);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const isFavorited = favorites.some((fav) => fav.id === property.id);

  const handleToggleFavorite = (e) => {
    e.stopPropagation();
    if (!isAuthenticated) {
      navigate("/signup"); // Show signup modal if not authenticated
    } else {
      if (isFavorited) {
        dispatch(removeFavorite(property.id));
      } else {
        dispatch(toggleFavorite(property));
      }
      window.dispatchEvent(new Event("favoriteUpdated"));
    }
  };

  return (
    <div
      key={property.id}
      className="border rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow relative h-auto"
    >
      <div className="relative">
        <Link to={`/home-detail/${property.id}`}>
          <img
            src={property.image}
            alt="Property"
            className="w-full h-48 object-cover"
          />
        </Link>
        {property.propertyStatus && (
          <span className="absolute top-2 left-2 bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded-full">
            {property.propertyStatus}
          </span>
        )}
        {showFavorite && (
          <button
            onClick={handleToggleFavorite}
            className="absolute top-2 right-2 bg-white rounded-full p-2 shadow-md"
          >
            <Heart
              size={24}
              color={isFavorited ? "red" : "gray"}
              fill={isFavorited ? "red" : "none"}
              className="transition-transform transform hover:scale-110"
            />
          </button>
        )}
      </div>
      <div className="p-4">
        <div className="flex items-center justify-between mb-1">
          <div className="text-red-500 text-sm">{property.type}</div>
          <div className="flex items-center text-gray-500">
            <MdLocationOn size={20} className="text-red-400 mr-1" />
            <span>{property.city}</span>
          </div>
          {isEditable && (
            <button
              onClick={() => setEditModalOpen(true)}
              className="bg-black text-white p-2 rounded-full transition-colors"
            >
              <Edit size={20} color="white" />
            </button>
          )}
          <EditModal
            isOpen={isEditModalOpen}
            onClose={() => setEditModalOpen(false)}
            property={property}
          />
        </div>

        <div className="text-lg md:text-xl font-semibold mb-1">
          {property.price} ETB
        </div>
        <div className="text-sm mb-1 flex justify-between items-center">
          <span className="flex items-center text-gray-800">
            {property.bed} bed {property.bath} bath • {property.sqft} sqft
          </span>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
