import React, { useState } from "react";
import { useFilterPropertiesByStatusQuery } from "../api/propertyApi";
import PropertyListings from "../../../components/PropertyListings";
import PropertyCardSkeleton from "../../../shared/PropertyCardSkeleton";

const ForRent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  // Fetch data using the page and filter parameters
  const {
    data: response,
    isLoading,
    error,
  } = useFilterPropertiesByStatusQuery({ propertyStatus: "For Rent" });

  // Handle pagination logic
  const totalPages = response?.totalPages || 1;

  const properties =
    response?.data?.map((property) => ({
      id: property._id,
      image: property.image[0],
      status: `New • ${new Date(property.createdAt).toLocaleTimeString()}`,
      price: property.price.toLocaleString(),
      bed: property.bedrooms || 0,
      bath: property.bathrooms || 0,
      city: property.city,
      sqft: property.size || "N/A",
      type: property.type,
    })) || [];

  // Handlers for pagination buttons
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="container mx-auto px-4 py-12">
      {isLoading ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {Array(6)
            .fill(null)
            .map((_, index) => (
              <PropertyCardSkeleton key={index} />
            ))}
        </div>
      ) : error ? (
        <div className="text-center text-red-500">
          Error fetching properties.
        </div>
      ) : (
        <>
          <PropertyListings properties={properties} />

          {/* Pagination Controls */}
          {/* <div className="flex justify-between mt-6">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
            >
              Previous
            </button>
            <span className="self-center text-lg font-medium">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
            >
              Next
            </button>
          </div> */}
        </>
      )}
    </div>
  );
};

export default ForRent;
