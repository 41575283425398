import React, { useState } from "react";
import { motion } from "framer-motion";
import { useGetCitiesQuery } from "../api/propertyApi";
import { useSelector } from "react-redux";
import { selectOwnerId } from "../../authentication/slices/authSlice";

const NewLocationStep = ({
  prevStep,
  formData,
  setFormData,
  openModal,
  onSubmit,
}) => {
  const { data: cities, isLoading, isError } = useGetCitiesQuery();
  const [areas, setAreas] = useState([]);
  const ownerId = useSelector(selectOwnerId);

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setFormData({ ...formData, city: selectedCity, area: "" });

    const cityData = cities?.data?.find((city) => city.name === selectedCity);
    setAreas(cityData?.areas || []);
  };

  const handleAreaChange = (e) => {
    setFormData({ ...formData, area: e.target.value });
  };

  const handleSubmit = () => {
    if (ownerId) {
      onSubmit();
    } else {
      openModal();
    }
  };

  return (
    <div className="flex items-center justify-center bg-white px-4">
      <div className="px-8 w-full max-w-3xl bg-white rounded-lg">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
            <div>
              <label htmlFor="city" className="block text-gray-700 mb-2">
                City
              </label>
              <select
                id="city"
                name="city"
                value={formData.city || ""}
                onChange={handleCityChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              >
                <option value="" disabled>
                  {isLoading ? "Loading cities..." : "Select a city"}
                </option>
                {isError ? (
                  <option disabled>
                    Error loading cities. Please try again.
                  </option>
                ) : (
                  cities?.data?.map((city) => (
                    <option key={city._id} value={city.name}>
                      {city.name}
                    </option>
                  ))
                )}
              </select>
            </div>

            {areas.length > 0 && (
              <div>
                <label htmlFor="area" className="block text-gray-700 mb-2">
                  Area
                </label>
                <select
                  id="area"
                  name="area"
                  value={formData.area || ""}
                  onChange={handleAreaChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="" disabled>
                    {isLoading ? "Loading areas..." : "Select an area"}
                  </option>
                  {isError ? (
                    <option disabled>
                      Error loading areas. Please try again.
                    </option>
                  ) : (
                    areas.map((area, index) => (
                      <option key={index} value={area}>
                        {area}
                      </option>
                    ))
                  )}
                </select>
              </div>
            )}
          </div>
        </motion.div>

        <div className="mt-6 flex justify-between items-center">
          <button
            onClick={prevStep}
            className="bg-black text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md hover:bg-gray-800 transition duration-150"
          >
            Back
          </button>

          <button
            onClick={handleSubmit}
            className="bg-red-500 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md hover:bg-red-600 transition duration-150"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewLocationStep;
