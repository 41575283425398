import React from "react";
import { Link } from "react-router-dom";
import home1 from "../assets/home1.jpg";
import home2 from "../assets/home6.jpg";
import home3 from "../assets/home3.jpg";
import home4 from "../assets/home4.jpg";
import home5 from "../assets/home5.jpg";
import home6 from "../assets/home6.jpg";
import home7 from "../assets/home7.jpg";
import home8 from "../assets/home8.jpg";

const BrowseHomes = () => {
  const categories = [
    { name: "Apartment", img: home1, count: 164 },
    { name: "Land", img: home2, count: 111 },
    { name: "Office", img: home3, count: 39 },
    { name: "Real Estate", img: home4, count: 1566 },
    { name: "Factory", img: home6, count: 7 },
    { name: "Condo", img: home7, count: 94 },
    { name: "House", img: home8, count: 205 },
    { name: "Warehouse", img: home7, count: 94 },
  ];

  return (
    <section className="py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-semibold mb-8">Browse homes by type</h2>
        <div className="relative">
          {/* Scrollable container with visible scrollbar */}
          <div className="flex md:grid md:grid-cols-4 gap-6 overflow-x-auto scrollbar scrollbar-thumb-gray-600 scrollbar-track-gray-300 scrollbar-thumb-rounded-full scrollbar-w-3 whitespace-nowrap">
            {categories.map((category, index) => (
              <Link
                key={index}
                to={`/properties/type/${category.name}`}
                className="inline-block w-64 md:w-auto flex-shrink-0 transition-transform transform hover:scale-105"
              >
                <div className="relative bg-gray-100 rounded-lg overflow-hidden shadow-lg">
                  {/* Dark Overlay */}
                  <div className="absolute inset-0 bg-black opacity-50 transition-opacity hover:opacity-30"></div>

                  <img
                    src={category.img}
                    alt={category.name}
                    className="w-full h-48 object-cover"
                  />

                  <div className="absolute top-2 left-2">
                    <span className="text-white text-sm font-semibold">
                      {category.name}
                    </span>
                  </div>

                  <span className="absolute top-2 right-2 bg-white text-gray-800 text-sm font-semibold px-2 py-1 rounded-full">
                    {category.count}
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrowseHomes;
