import React, { useState } from "react";
import { useFilterPropertiesByStatusPaginatedQuery} from "../features/manage-property/api/propertyApi";
import PropertyListings from "./PropertyListings";
import PropertyCardSkeleton from "../shared/PropertyCardSkeleton";

const ForSaleOnHome = () => {
  // State to track the current page
  const currentPage = 1;
  const limit = 9; // Set the number of items per page

  // Fetch data using the page and filter parameters
  const {
    data: response,
    isLoading,
    error,
  } = useFilterPropertiesByStatusPaginatedQuery({
    propertyStatus: "For Sale",
    page: currentPage,
    limit: limit,
  });

  // Handle pagination logic
  const totalPages = response?.totalPages || 1;

  const properties =
    response?.data?.map((property) => ({
      id: property._id,
      image: property.image[0],
      status: `New • ${new Date(property.createdAt).toLocaleTimeString()}`,
      price: property.price.toLocaleString(),
      bed: property.saleDetail?.bedrooms || 0,
      bath: property.saleDetail?.bathrooms || 0,
      city: property.city,
      sqft: property.saleDetail?.squareFootage || "N/A",
      type: property.type,
    })) || [];

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-semibold mb-[-20px]">Homes For Sale </h2>
      {isLoading ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {Array(6)
            .fill(null)
            .map((_, index) => (
              <PropertyCardSkeleton key={index} />
            ))}
        </div>
      ) : error ? (
        <div className="text-center text-red-500">
          Error fetching properties.
        </div>
      ) : (
        <>
          <PropertyListings properties={properties} />
        </>
      )}
    </div>
  );
};

export default ForSaleOnHome;
