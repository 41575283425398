import React from "react";
import PropertyCard from "./PropertyCard";
import NewPropertyCard from "./NewPropertyCard";

const PropertyListings = ({ properties, isEditable, showFavorite = true }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 px-2 py-12">
      {properties.map((property, index) => (
        <PropertyCard
          key={index}
          property={property}
          isEditable={isEditable}
          showFavorite={showFavorite}
        />
      ))}
    </div>
  );
};

export default PropertyListings;
