import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import {
  useGetOwnerDetailsQuery,
  useUpdateProfileMutation,
} from "../api/authApi";
import { selectOwnerInfo } from "../slices/authSlice";

const EditProfile = () => {
  const ownerInfo = useSelector(selectOwnerInfo);
  // console.log(ownerInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: owner } = useGetOwnerDetailsQuery(id);
  // console.log("ownerrr", owner?.owner.phoneNumber);

  const [editProfile, { isLoading, error, isSuccess }] =
    useUpdateProfileMutation();
  const [formData, setFormData] = useState({
    firstName: owner?.owner.firstName || "",
    lastName: owner?.owner.lastName || "",
    email: owner?.owner.email || "",
    phoneNumber: owner?.owner.phoneNumber || "",
  });

  useEffect(() => {
    if (isSuccess) {
      navigate("/profile"); // Navigate to profile page on successful update
    }
  }, [isSuccess, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await editProfile({ id, ...formData });
    navigate("/profile");
    window.location.reload();
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br bg-white mt-12">
      <div className="rounded-lg p-8 w-full max-w-md">
        {/* Back Button */}
        <button
          onClick={() => navigate("/profile")}
          className="flex items-center gap-2 text-gray-800 mb-6"
        >
          <MdArrowBack />
          Back to Profile
        </button>

        {/* Edit Profile Form */}
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Name Fields */}
          <div className="flex gap-4">
            <div className="w-full">
              <label htmlFor="firstName" className="block text-gray-600">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                className="w-full p-3 rounded-lg border bg-gray-50 mt-2"
                required
              />
            </div>
            <div className="w-full">
              <label htmlFor="lastName" className="block text-gray-600">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                className="w-full p-3 rounded-lg border bg-gray-50 mt-2"
                required
              />
            </div>
          </div>

          {/* Email */}
          <div>
            <label htmlFor="email" className="block text-gray-600">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full p-3 rounded-lg border bg-gray-50 mt-2"
              required
            />
          </div>

          {/* Phone Number */}
          <div>
            <label htmlFor="phoneNumber" className="block text-gray-600">
              Phone Number
            </label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              className="w-full p-3 rounded-lg border bg-gray-50 mt-2"
              required
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={isLoading}
            className="w-full p-3 bg-black text-white rounded-lg mt-4 disabled:bg-gray-500"
          >
            {isLoading ? "Updating..." : "Save Changes"}
          </button>

          {/* Error Message */}
          {error && (
            <div className="mt-4 text-red-600 text-center">{error.message}</div>
          )}
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
