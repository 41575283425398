import React, { useEffect, useState } from "react";
import {
  useSigninMutation,
  useSendOtpMutation,
  useVerifyOtpMutation,
  // useSignupMutation,
} from "../api/authApi";
import { Link, useNavigate } from "react-router-dom";
import { setToken } from "../slices/authSlice";
import { useDispatch } from "react-redux";

// import { signInWithGoogle } from "../../../firebase";
// import google from "../../../assets/google.png";

const OtpData = {
  success: false,
  messageId: null,
  message: null,
  to: null,
  code: null,
  verificationId: null,
};

const Signin = () => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otpResponse, setOtpResponse] = useState({ ...OtpData });
  const [timer, setTimer] = useState(300);
  const [signin, { isLoading }] = useSigninMutation();
  // const [signup] = useSignupMutation();
  const [sendOtp, { isLoading: otpSending }] = useSendOtpMutation();
  const [verifyOtp, { isLoading: otpVerifying }] = useVerifyOtpMutation();
  const navigate = useNavigate();

  useEffect(() => {
    let countdown;
    if (showOtpInput && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setShowOtpInput(false);
    }
    return () => clearInterval(countdown);
  }, [showOtpInput, timer]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendOtp({ phoneNumber }).unwrap();
      console.log("OTP sent successfully:", response);
      setOtpResponse({
        success: response.success,
        messageId: response.data.message_id,
        message: response.data.message,
        to: response.data.to,
        code: response.data.code,
        verificationId: response.data.verificationId,
      });
      setShowOtpInput(true);
      setTimer(300);
    } catch (error) {
      console.error("Failed to send OTP:", error);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await verifyOtp({
        phoneNumber,
        otp,
        messageId: otpResponse.messageId,
        message: otpResponse.message,
        to: otpResponse.to,
        code: otpResponse.code,
        verificationId: otpResponse.verificationId, // Use the verificationId from the OTP response
      }).unwrap();

      if (response.success) {
        console.log("OTP verified successfully.");
        handleSignIn(); // Proceed to sign in
      } else {
        alert("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Failed to verify OTP:", error);
    }
  };

  const handleSignIn = async () => {
    try {
      const response = await signin({ phoneNumber, password }).unwrap();
      console.log("Signin successful:", response);
      dispatch(setToken(response));
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Failed to sign in:", error);
    }
  };

  // const handleGoogleSignIn = async () => {
  //   try {
  //     const user = await signInWithGoogle();
  //     console.log("Google Sign-In Successful:", user.providerData);
  //     const { displayName, email } = user;

  //     const [firstName, ...lastNameParts] = displayName.split(" ");
  //     const lastName = lastNameParts.join(" ") || "";

  //     const userData = {
  //       firstName,
  //       lastName,
  //       email,
  //     };

  //     const response = await signup(userData).unwrap();
  //     console.log("Google Sign-In Response:", response);
  //     dispatch(setToken(response));
  //     navigate("/");
  //     window.location.reload();
  //   } catch (error) {
  //     console.error("Google Sign-In Error:", error);
  //   }
  // };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg w-full max-w-md">
        <h2 className="text-2xl font-bold text-center mb-6">Sign in</h2>
        {!showOtpInput ? (
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                type="text"
                className="mt-1 block w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                className="mt-1 block w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 mt-6 right-3 flex items-center cursor-pointer text-gray-500"
              >
                {showPassword ? "👁️" : "🙈"}
              </span>
            </div>
            <div>
              <button
                type="submit"
                className="w-full p-3 bg-black text-white rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={isLoading || otpSending}
              >
                {isLoading || otpSending ? "Sending OTP..." : "Send OTP"}
              </button>
            </div>
          </form>
        ) : (
          <div className="space-y-4">
            <label className="block text-sm font-medium text-gray-700">
              Enter OTP
            </label>
            <input
              type="text"
              className="mt-1 block w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <p className="text-red-500 text-sm">
              OTP expires in {Math.floor(timer / 60)}:
              {(timer % 60).toString().padStart(2, "0")}
            </p>

            <button
              className="w-full p-3 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
              onClick={handleVerifyOtp}
              disabled={otpVerifying}
            >
              {otpVerifying ? "Verifying OTP..." : "Verify OTP"}
            </button>
          </div>
        )}

        {/* Google Sign-In Button */}
        {/* <div className="mt-4 text-center">
          <button
            onClick={handleGoogleSignIn}
            className="w-full flex items-center justify-center gap-3 p-3 border border-gray-300 rounded-lg shadow-sm bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300"
          >
            <img src={google} className="w-5 h-5" alt="Google" />
            <span className="text-gray-700 font-medium">
              Sign up with Google
            </span>
          </button>
        </div> */}

        <div className="mt-4 text-center">
          <p className="text-sm text-gray-600">
            Don't have an account?{" "}
            <Link to="/signup" className="text-red-400 hover:underline">
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signin;
