import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../../constants";
import { getTokenFromCookies } from "../../../shared/getToken.mjs";

export const propertyApi = createApi({
  reducerPath: "propertyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = getTokenFromCookies();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProperties: builder.query({
      query: () => `property/`,
    }),
    getCities: builder.query({
      query: () => `settings/cities`,
    }),
    getPropertyDetails: builder.query({
      query: (id) => `property/${id}`,
    }),

    getOwnerLists: builder.query({
      query: () => `property/owner`,
    }),

    getPropertyById: builder.query({
      query: (id) => ({
        url: `property/${id}`,
      }),
    }),
    getNotifications: builder.query({
      query: () => `notification/my/owner`,
    }),
    checkNotification: builder.mutation({
      query: (_id) => ({
        url: `notification/check/${_id}`,
        method: "GET",
      }),
    }),
    getFaqs: builder.query({
      query: () => `faq/`,
    }),
    addProperty: builder.mutation({
      query: (data) => ({
        url: `property/`,
        method: "POST",
        body: data,
      }),
    }),
    contact: builder.mutation({
      query: (data) => ({
        url: `contact/`,
        method: "POST",
        body: data,
      }),
    }),
    updateProperty: builder.mutation({
      query: (data) => ({
        url: `property/${data._id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    phonenumberChecked: builder.mutation({
      query: (id) => ({
        url: `property/${id}`,
        method: "PATCH",
      }),
    }),

    filterPropertiesByStatusPaginated: builder.query({
      query: ({ propertyStatus, page = 1, limit = 9 }) =>
        `property/?propertyStatus=${encodeURIComponent(
          propertyStatus
        )}&page=${page}&limit=${limit}`,
    }),
    bigFilter: builder.query({
      query: ({ city, price, type, propertyStatus, area }) => {
        let query = `property/?`;

        if (city) query += `&city=${encodeURIComponent(city)}`;
        if (price) query += `&price[lt]=${encodeURIComponent(price)}`;
        if (type) query += `&type=${encodeURIComponent(type)}`;
        if (propertyStatus)
          query += `&propertyStatus=${encodeURIComponent(propertyStatus)}`;
        if (area) query += `&area=${encodeURIComponent(area)}`;

        return query;
      },
    }),

    filterPropertiesByCity: builder.query({
      query: (city) => `property/?city=${encodeURIComponent(city)}`,
    }),
    filterPropertiesByStatus: builder.query({
      query: (status) =>
        `property/?propertyStatus=${encodeURIComponent(status)}`,
    }),

    filterPropertiesByType: builder.query({
      query: (type) => `property/?type=${encodeURIComponent(type)}`,
    }),
  }),
});

export const {
  useGetPropertiesQuery,
  useAddPropertyMutation,
  useGetPropertyByIdQuery,
  useFilterPropertiesByStatusQuery,
  useGetOwnerListsQuery,
  useFilterPropertiesByTypeQuery,
  useGetPropertyDetailsQuery,
  useGetCitiesQuery,
  useUpdatePropertyMutation,
  useFilterPropertiesByCityQuery,
  useBigFilterQuery,
  useGetNotificationsQuery,
  usePhonenumberCheckedMutation,
  useContactMutation,
  useFilterPropertiesByStatusPaginatedQuery,
  useGetFaqsQuery,
  useCheckNotificationMutation,
} = propertyApi;
