import Cookies from "js-cookie";
import { createSlice } from "@reduxjs/toolkit";

// Helper function to parse JSON from localStorage safely
const parseLocalStorageItem = (key) => {
  const item = localStorage.getItem(key);
  try {
    return JSON.parse(item);
  } catch (error) {
    console.error(`Error parsing JSON from localStorage key "${key}":`, error);
    return null;
  }
};

const initialState = {
  token: Cookies.get("token") || null,
  isAuthenticated: parseLocalStorageItem("isAuthenticated") || false,
  ownerId: parseLocalStorageItem("ownerId") || null,
  ownerInfo: parseLocalStorageItem("ownerInfo") || {}, // Default to an empty object to avoid null errors
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      // console.log("payloaddd", action.payload.data);
      state.token = action.payload.data.token;
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem(
        "ownerInfo",
        JSON.stringify(action.payload.data.owner || {}) // Ensure owner is not null
      );
      localStorage.setItem(
        "ownerId",
        JSON.stringify(action.payload.data.owner?._id || "") // Use optional chaining to handle potential null or undefined
      );
      Cookies.set("token", state.token);
    },
    setTokenAfterSignup: (state, action) => {
      console.log("payloaddd", action.payload.data);
      state.token = action.payload.data.token;
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem(
        "ownerInfo",
        JSON.stringify(action.payload.data.newOwner || {}) // Ensure newOwner is not null
      );
      localStorage.setItem(
        "ownerId",
        JSON.stringify(action.payload.data.newOwner?._id || "") // Use optional chaining to handle potential null or undefined
      );
      Cookies.set("token", state.token);
    },
    logout: (state) => {
      state.token = null;
      localStorage.setItem("isAuthenticated", false);
      localStorage.removeItem("ownerId");
      localStorage.removeItem("ownerInfo");
      Cookies.remove("token");
    },
  },
});

export const { setToken, logout, setTokenAfterSignup } = authSlice.actions;

export default authSlice.reducer;

export const selectToken = (state) => state.auth.token;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectOwnerId = (state) => state.auth.ownerId;
export const selectOwnerInfo = (state) => state.auth.ownerInfo;
