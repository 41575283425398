import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import {
  useGetPropertyDetailsQuery,
  usePhonenumberCheckedMutation,
} from "../features/manage-property/api/propertyApi";
import { PhoneIcon } from "lucide-react";

const HomeDetail = () => {
  const { id } = useParams();
  const { data: property, isLoading, error } = useGetPropertyDetailsQuery(id);
  const [phonenumberChecked] = usePhonenumberCheckedMutation();
  // const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const images = property?.data.image || [];
  const [currentIndex, setCurrentIndex] = useState(0); // Track the image index
  const thumbnails = images.slice(1, 4);

  const amenityIcons = {
    "Swimming Pool": (
      <svg
        className="w-5 h-5 text-red-500"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M3 18s2-2 4-2 4 2 4 2 2-2 4-2 4 2 4 2"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 15V5a2 2 0 012-2h10a2 2 0 012 2v10"
        />
      </svg>
    ),
    Security: (
      <svg
        className="w-5 h-5 text-red-500"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 2L3 8v6a9 9 0 0018 0V8l-9-6z"
        />
      </svg>
    ),
    Garden: (
      <svg
        className="w-5 h-5 text-red-500"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 2v20m0 0H4m8 0h8M7 10h10m-5-5v10"
        />
      </svg>
    ),
    Elevator: (
      <svg
        className="w-5 h-5 text-red-500"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8 3h8a2 2 0 012 2v14a2 2 0 01-2 2H8a2 2 0 01-2-2V5a2 2 0 012-2z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 8h4m-2 6V8"
        />
      </svg>
    ),
    Gym: (
      <svg
        className="w-5 h-5 text-red-500"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M6 6h12M6 18h12m-6-6v6m0-6V6"
        />
      </svg>
    ),
    Parking: (
      <svg
        className="w-5 h-5 text-red-500"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8 16V8h4a4 4 0 010 8H8zM16 12h2m-2 0V8m0 4v4m0-4h-2"
        />
      </svg>
    ),
    Playground: (
      <svg
        className="w-5 h-5 text-red-500"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 20h16m-8-4v-6m0 6H6m8 0h6"
        />
      </svg>
    ),
  };
  // Predefined coordinates for Jimma
  const jimmaLatitude = 7.6667;
  const jimmaLongitude = 36.8333;

  const handlePhoneNumberClicked = async (id, phoneNumber) => {
    try {
      await phonenumberChecked(id).unwrap();
      if (phoneNumber) {
        window.location.href = `tel:${phoneNumber}`;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const items = images.map((image, index) => (
    <img
      key={index}
      src={image}
      alt={`Property Image ${index + 1}`}
      className="w-full h-[400px] object-cover object-center"
    />
  ));

  return (
    <div className="container mx-auto px-4 py-8 pt-20">
      {/* Added pt-20 for top padding */}
      {isLoading && (
        <div className="flex justify-center items-center py-12">
          <p className="text-gray-500">Loading property details...</p>
        </div>
      )}
      {error && (
        <div className="text-center text-red-500 py-12">
          Error fetching property details.
        </div>
      )}
      {!isLoading && !error && property && (
        <div className="space-y-6">
          {/* Image Gallery */}
          <div className="flex gap-4">
            <div className="flex-1 relative rounded-lg overflow-hidden">
              <AliceCarousel
                items={items}
                autoPlay
                autoPlayInterval={3000}
                infinite
                disableDotsControls
                renderPrevButton={() => (
                  <button className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 rounded-full p-2 z-10">
                    <span className="sr-only">Previous</span>
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                  </button>
                )}
                renderNextButton={() => (
                  <button className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 rounded-full p-2 z-10">
                    <span className="sr-only">Next</span>
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                )}
              />
              <div className="absolute top-4 left-4 flex gap-2">
                {/* <span className="bg-red-500 text-white text-xs px-3 py-1 rounded-full">
                  New • 4 hours ago
                </span> */}
                {/* <span className="bg-black/75 text-white text-xs px-3 py-1 rounded-full">
                  New construction
                </span> */}
              </div>
              {/* <span className="absolute bottom-8 left-4 bg-black/50 text-white text-xs px-2 py-1 rounded-full">
                {currentIndex + 1}/{images.length}{" "}
              </span> */}
            </div>
            <div className="hidden md:grid grid-rows-3 gap-4 w-48">
              {thumbnails.map((image, index) => (
                <div
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  className="relative"
                >
                  <img
                    src={image}
                    alt={`Property view ${index + 2}`}
                    className="w-full h-32 object-cover object-center rounded-lg"
                  />
                  {/* <span className="absolute bottom-2 left-2 bg-black/75 text-white text-xs px-2 py-1 rounded-full">
                    {["2", "3", "4"][index]}
                  </span> */}
                </div>
              ))}
            </div>
          </div>

          {/* How much home can you afford? */}
          {/* <button className="text-blue-600 text-sm hover:underline">
            How much home can you afford?
          </button> */}

          {/* Property Details */}
          <div>
            <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-200 text-red-500  mb-2">
              House {property?.data?.propertyStatus}
            </div>
            <h1 className="text-3xl font-bold">
              {property?.data?.price?.toLocaleString()} ETB
            </h1>
            <div className="flex items-center gap-2 text-gray-600 mt-1">
              <span className="text-nowrap">
                {property?.data?.bedrooms} bedrooms
              </span>
              <span>•</span>
              <span className="text-nowrap">
                {property?.data?.bathrooms} bathrooms
              </span>
              <span>•</span>
              <span className="text-nowrap">{property?.data?.size} sqft</span>
            </div>
            <p className="text-red-500 text-nowrap mt-1">
              <span className="text-gray-600">Location :</span>{" "}
              {property?.data?.city},{property?.data?.area}
            </p>
          </div>

          {/* Est. Payment */}
          {/* <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <span className="text-gray-600">Est. 2,591 ETB/mo</span>
              <svg
                className="w-4 h-4 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
          </div> */}
          {/* Action Buttons */}
          <div className="gap-4">
            <button
              onClick={() =>
                handlePhoneNumberClicked(
                  property?.data?._id,
                  property?.data?.owner?.phoneNumber
                )
              }
              className="flex items-center justify-center gap-1 bg-gray-900 text-white px-6 py-3 rounded-full shadow-md hover:bg-gray-800 hover:shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 focus:outline-none focus:ring-2 focus:ring-gray-700"
            >
              <PhoneIcon className="w-5 h-5" />
              <span className="font-medium">Call</span>
            </button>
          </div>
          <div className="text-center mb-12">
            <h2 className="text-3xl font-semibold text-gray-800">Amenities</h2>
            <div className="border-b-2 w-24 mx-auto my-2"></div>
          </div>
          {/* Property Features */}
          <div className="grid grid-cols-2 md:grid-cols-3 gap-6 p-4">
            {property?.data?.buildingAmenities?.map((amenity, index) => (
              <div key={index}>
                <div className="flex items-center gap-2">
                  {amenityIcons[amenity] || ( // Use predefined icon or fallback
                    <svg
                      className="w-5 h-5 text-red-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2z"
                      />
                    </svg>
                  )}
                  <span className="font-medium">{amenity}</span>
                </div>
              </div>
            ))}
          </div>

          {/* Map Section */}
          <div className="space-y-2">
            <div className="h-96 bg-gray-100 rounded-lg overflow-hidden">
              <iframe
                title="Property location"
                className="w-full h-full"
                src={`https://www.google.com/maps?q=${jimmaLatitude},${jimmaLongitude}&output=embed`}
              />
            </div>
            {/* <button className="text-blue-600 text-sm hover:underline flex items-center gap-2">
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              Add a commute
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeDetail;