const Checkbox = ({ label, name, value, checked, onChange }) => {
  return (
    <div className="flex items-center space-x-2">
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="h-4 w-4 border-gray-300 rounded-md focus:ring focus:ring-blue-300"
      />
      <span className="text-sm">{label}</span>
    </div>
  );
};

export default Checkbox;
