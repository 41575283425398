import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./components/Header";
import Hero from "./components/Hero";
import BrowseHomes from "./components/BrowseHomes";
// import ServiceSection from "./components/ServiceSection";
// import FeaturedArticle from "./components/FeaturedArticle";
import Footer from "./components/Footer";
import MobileBottomNav from "./components/MobileBottomNav";
import ScrollToTop from "./shared/ScrollToTop";
import SearchResults from "./pages/SearchResults";
import HomeDetail from "./pages/HomeDetail";
import AddProperty from "./features/manage-property/pages/AddProperty";
import Signin from "./features/authentication/pages/Signin";
import ForSale from "./features/manage-property/pages/ForSale";
import ForRent from "./features/manage-property/pages/ForRent";
import MyList from "./features/manage-property/pages/MyList";
import PropertyByType from "./features/manage-property/pages/PropertyByType";
import SavedProperties from "./features/manage-property/pages/SavedProperties";
import AddPropertyForSignedUser from "./features/manage-property/pages/AddPropertyForSignedUser";
import Profile from "./features/authentication/pages/Profile";
import PropertyInCity from "./features/manage-property/pages/PropertyInCity";
import FilteredProperty from "./features/manage-property/pages/FilteredProperty";
import ForSaleOnHome from "./components/ForSaleOnHome";
import SignUp from "./features/authentication/pages/SignUp";
import EditProfile from "./features/authentication/pages/EditProfile";
import ContactUs from "./features/authentication/pages/ContactUs";
import { selectIsAuthenticated } from "./features/authentication/slices/authSlice";
import FAQ from "./components/Faq";
import NewSignWithOtp from "./features/authentication/pages/NewSignWithOtp";


function App() {
  const location = useLocation();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const showHeaderAndFooter =
    location.pathname !== "/add-property" && location.pathname !== "/post";

  return (
    <div className="flex flex-col min-h-screen font-poppins">
      {showHeaderAndFooter && <Header />}

      <div className="flex-grow">
        <ScrollToTop />
        <Routes>
          {/* Public Routes */}
          <Route
            path="/"
            element={
              <>
                <Hero />
                <BrowseHomes />
                <ForSaleOnHome/>
                <FAQ/>
                {/* <FeaturedArticle /> */}
              </>
            }
          />
          <Route path="/for-sale" element={<ForSale />} />
          <Route path="/for-rent" element={<ForRent />} />
          <Route path="/properties/type/:type" element={<PropertyByType />} />
          <Route path="/properties/city/:city" element={<PropertyInCity />} />
          <Route path="/properties" element={<FilteredProperty />} />
          <Route path="/search-results" element={<SearchResults />} />
          <Route path="/home-detail/:id" element={<HomeDetail />} />
          <Route path="/signin" element={<Signin />} />
          {/* <Route path="/signin" element={<NewSignWithOtp />} /> */}
          <Route path="/signup" element={<SignUp />} />
          <Route path="/contact-us" element={<ContactUs />} />

          {/* Protected Routes */}
          {isAuthenticated ? (
            <>
              <Route path="/saved-properties" element={<SavedProperties />} />
              <Route path="/my-list" element={<MyList />} />
              <Route path="/add-property" element={<AddProperty />} />
              <Route path="/post" element={<AddPropertyForSignedUser />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/edit/:id" element={<EditProfile />} />
            </>
          ) : (
            // Redirect to Signin for any protected route
            <Route
              path="*"
              element={<Navigate to="/signin" replace />}
            />
          )}
        </Routes>
      </div>

      {showHeaderAndFooter && <Footer />}
      {showHeaderAndFooter && <MobileBottomNav />}
    </div>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
