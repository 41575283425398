import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetCitiesQuery } from "../features/manage-property/api/propertyApi";
import InputField from "../features/manage-property/components/InputField";
import SelectField from "../features/manage-property/components/SelectField";
import RadioButton from "../features/manage-property/components/RadioButton";

const FilterModal = ({ onClose }) => {
  const { data: cities, isLoading, isError } = useGetCitiesQuery();
  const [areas, setAreas] = useState([]);
  const [formData, setFormData] = useState({
    city: "",
    area: "",
    type: "",
    price: "",
    propertyStatus: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (formData.city) {
      const selectedCity = cities?.data?.find((city) => city.name === formData.city);
      setAreas(selectedCity?.areas || []);
    }
  }, [formData.city, cities]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Update the filters in the URL
    const newFilters = {
      city: formData.city,
      area: formData.area,
      type: formData.type,
      price: formData.price,
      propertyStatus: formData.propertyStatus,
    };
    const searchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(newFilters)) {
      if (value) searchParams.set(key, value);
    }
    navigate(`/properties/?${searchParams.toString()}`);
    onClose(); // Close modal after applying the filter
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 mt-20">
      <div className="bg-white w-11/12 sm:w-2/3 md:w-1/2 lg:w-1/3 rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Filter by</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Price Range */}
          <div>
            <label
              htmlFor="price"
              className="block text-sm font-medium text-gray-700"
            >
              Maximum price
            </label>
            <InputField
              name="price"
              value={formData.price}
              onChange={handleChange}
              placeholder="Enter the Max.price"
            />
          </div>

          {/* Property Type */}
          <div>
            <label
              htmlFor="propertyType"
              className="block text-sm font-medium text-gray-700"
            >
              Property Type
            </label>
            <SelectField
              name="type"
              value={formData.type}
              onChange={handleChange}
              options={[
                "Apartment", "Condo", "Land", "House", "Office & Shop", "Residence Villa", 
                "Real Estate", "Commercial Area", "Commercial Building", "Warehouse", 
                "Factory/Industry", "Guest House", "Other"
              ]}
            />
          </div>

          {/* City */}
          <div>
            <label
              htmlFor="city"
              className="block text-sm font-medium text-gray-700"
            >
              City
            </label>
            <select
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="mt-1 p-2 block w-full rounded-md border-gray-300 border-2 shadow-sm outline-none text-sm"
            >
              <option value="">Select a city</option>
              {isLoading ? (
                <option disabled>Loading cities...</option>
              ) : isError ? (
                <option disabled>Error loading cities. Please try again.</option>
              ) : (
                cities?.data?.map((city) => (
                  <option key={city._id} value={city.name}>
                    {city.name}
                  </option>
                ))
              )}
            </select>
          </div>

          {/* Area */}
          <div>
            <label
              htmlFor="area"
              className="block text-sm font-medium text-gray-700"
            >
              Area
            </label>
            <select
              name="area"
              value={formData.area}
              onChange={handleChange}
              className="mt-1 p-2 block w-full rounded-md border-gray-300 border-2 shadow-sm outline-none text-sm"
              disabled={!areas.length}
            >
              <option value="">Select an area</option>
              {areas.map((area, index) => (
                <option key={index} value={area}>
                  {area}
                </option>
              ))}
            </select>
          </div>

          {/* Status */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Status
            </label>
            <div className="flex flex-wrap gap-4">
              <RadioButton
                label="For Sale"
                name="propertyStatus"
                value="For Sale"
                checked={formData.propertyStatus === "For Sale"}
                onChange={handleChange}
              />
              <RadioButton
                label="For Rent"
                name="propertyStatus"
                value="For Rent"
                checked={formData.propertyStatus === "For Rent"}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="flex justify-between mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-black text-white rounded-lg shadow hover:bg-black/80"
            >
              Apply Filter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FilterModal;
