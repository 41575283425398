import React from "react";
import logo from "../assets/logo-footer2.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-16">
      <div className="container mx-auto px-4 md:px-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {/* Logo and Description */}
          <div>
            <Link to="/">
              <img src={logo} alt="Logo" className="h-10 mb-6" />
            </Link>
            <p className="text-gray-400 leading-relaxed">
              Discover how Betale connects sellers and buyers seamlessly.
            </p>
          </div>

          {/* Learn About Betale Section */}
          <div>
            <h3 className="font-semibold text-lg mb-4">Learn About Betale</h3>
            <ul>
              <li className="mb-3">
                <Link
                  to="/contact-us"
                  className="text-gray-400 hover:text-white transition"
                >
                  About Betale
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  to="/contact-us"
                  className="text-gray-400 hover:text-white transition"
                >
                  Agent vs Owner
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  to="/contact-us"
                  className="text-gray-400 hover:text-white transition"
                >
                  Commitment to Excellence
                </Link>
              </li>
            </ul>
          </div>

          {/* For Homeowners Section */}
          <div>
            <h3 className="font-semibold text-lg mb-4">For Homeowners</h3>
            <ul>
              <li className="mb-3">
                <Link
                  to="/contact-us"
                  className="text-gray-400 hover:text-white transition"
                >
                  Look for the Betale
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  to="/contact-us"
                  className="text-gray-400 hover:text-white transition"
                >
                  Find a Buyer
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  to="/contact-us"
                  className="text-gray-400 hover:text-white transition"
                >
                  Real Estate Today
                </Link>
              </li>
            </ul>
          </div>

          {/* For Renters Section */}
          <div>
            <h3 className="font-semibold text-lg mb-4">For Renters</h3>
            <ul>
              <li className="mb-3">
                <Link
                  to="/contact-us"
                  className="text-gray-400 hover:text-white transition"
                >
                  Create social media content with Photofy
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  to="/contact-us"
                  className="text-gray-400 hover:text-white transition"
                >
                  Betale operating principles
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-12 border-t border-gray-700 pt-6 text-center text-gray-400">
          <p>©YAI 2024. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
