import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectOwnerInfo } from "../slices/authSlice";
import { FiPhone, FiMail, FiEdit } from "react-icons/fi";
import { MdLogout } from "react-icons/md";
import { useGetOwnerDetailsQuery } from "../api/authApi";


const Profile = () => {
  const ownerInfo = useSelector(selectOwnerInfo);
  const id = ownerInfo._id
  // console.log(ownerInfo);
  const {data:owner} = useGetOwnerDetailsQuery(id)
  // console.log('ownerrr',owner?.owner.phoneNumber)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("favorites");
    navigate("/signin");
    window.location.reload();
  };
  const initials =
  owner?.owner.firstName && owner?.owner.lastName
      ? owner?.owner.firstName.charAt(0) + owner?.owner.lastName.charAt(0)
      : "U";

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br bg-gray-100 mt-12">
      <div className="rounded-lg p-8 w-full max-w-md">
        {/* Profile Picture */}
        <div className="flex justify-center">
          <div className="h-20 w-20 rounded-full bg-black text-white flex items-center justify-center text-2xl font-bold">
            {initials}
          </div>
        </div>

        {/* User Info */}
        <div className="text-center mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">
            {owner?.owner.firstName} {owner?.owner.lastName}
          </h2>
          <div className="mt-2 flex justify-center items-center gap-2">
            <Link
              to={`/edit/${owner?.owner._id}`}
              className="text-sm text-red-600 cursor-pointer"
            >
              Edit Profile
            </Link>
            <Link to={`/edit/${owner?.owner._id}`}>
              <FiEdit className="text-red-600 text-lg cursor-pointer " />
            </Link>
          </div>
        </div>

        {/* Interactable Info */}
        <div className="mt-8 space-y-4">
          <div className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg shadow hover:bg-gray-100 cursor-pointer">
            <FiPhone className="text-red-500 text-xl" />
            <span className="text-gray-700 font-medium">
              {owner?.owner.phoneNumber}
            </span>
          </div>

          <div className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg shadow hover:bg-gray-100 cursor-pointer">
            <FiMail className="text-red-500 text-xl" />
            <span className="text-gray-700 font-medium">{owner?.owner.email}</span>
          </div>
        </div>

        {/* Logout Button */}
        <button
          onClick={handleLogout}
          className="mt-8 w-full flex items-center justify-center gap-2 p-3 bg-black text-white rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-red-500"
        >
          <MdLogout className="text-xl" />
          Logout
        </button>
      </div>
    </div>
  );
};

export default Profile;
