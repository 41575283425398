import React from "react";
import PropertyListings from "../../../components/PropertyListings";
import PropertyCardSkeleton from "../../../shared/PropertyCardSkeleton";
import { useGetOwnerListsQuery } from "../api/propertyApi";
import emptyIllustration from "../../../assets/no-home.jpg";
import { Link } from "react-router-dom";

const MyList = () => {
  const { data: response, isLoading, error } = useGetOwnerListsQuery();

  const properties =
    response?.data?.map((property) => ({
      id: property._id,
      image: property.image,
      propertyStatus: property.propertyStatus,
      price: property.price.toLocaleString(),
      city: property.city,
      area: property.area,
      bed: property.bedrooms || 0,
      bath: property.bathrooms || 0,
      sqft: property.size || "N/A",
      type: property.type,
      title: property.title,
      unitNumber: property.unitNumber,
      floorNumber: property.floorNumber,
      footTraffic: property.footTraffic,
      leaseTerms: property.leaseTerms,
      parkingSpaces: property.parkingSpaces,
      amenities: property.buildingAmenities,
    })) || [];

  return (
    <div className="container mx-auto px-6 mt-8 py-12">
      {isLoading ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {Array(6)
            .fill(null)
            .map((_, index) => (
              <PropertyCardSkeleton key={index} />
            ))}
        </div>
      ) : error || properties.length === 0 ? (
        <div className="flex flex-col items-center">
          <img
            src={emptyIllustration}
            alt="No properties added"
            className="w-2/3 md:w-1/3 lg:w-1/5 mb-8"
          />
          <p className="text-lg md:text-2xl text-gray-600 text-center">
            You haven't added any properties yet.
          </p>
          <Link
            to="/add-property"
            className="mt-6 bg-black text-white px-4 py-2 rounded-lg transition-colors hover:bg-gray-800"
          >
            List Your Property First
          </Link>
        </div>
      ) : (
        <PropertyListings
          properties={properties}
          isEditable
          showFavorite={false}
        />
      )}
    </div>
  );
};

export default MyList;
