import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropertyListings from "../../../components/PropertyListings";
import PropertyCardSkeleton from "../../../shared/PropertyCardSkeleton";
import { useBigFilterQuery, useGetCitiesQuery } from "../api/propertyApi";

const FilteredProperty = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 9; // Set the number of items per page

  const location = useLocation();
  const navigate = useNavigate();

  // Extract query parameters from URL
  const queryParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState({
    city: queryParams.get("city") || "",
    area: queryParams.get("area") || "",
    type: queryParams.get("type") || "",
    price: queryParams.get("price") || "",
    propertyStatus: queryParams.get("propertyStatus") || "",
  });

  // Temporary state for form inputs
  const [tempFilters, setTempFilters] = useState(filters);

  const { data: cities } = useGetCitiesQuery();
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    if (tempFilters.city) {
      const selectedCity = cities?.data?.find(
        (city) => city.name === tempFilters.city
      );
      setAreas(selectedCity?.areas || []);
    } else {
      setAreas([]);
    }
  }, [tempFilters.city, cities]);

  // Fetch data using the page and filter parameters
  const {
    data: response,
    isLoading,
    error,
  } = useBigFilterQuery({
    ...filters,
    page: currentPage,
    limit: limit,
  });

  const totalPages = response?.totalPages || 1;

  const properties =
    response?.data?.map((property) => ({
      id: property._id,
      image: property.image[0],
      status: `New • ${new Date(property.createdAt).toLocaleTimeString()}`,
      price: property.price.toLocaleString(),
      bed: property.bedrooms || 0,
      bath: property.bathrooms || 0,
      city: property.city,
      sqft: property.size || "N/A",
      type: property.type,
    })) || [];

  // Handlers for pagination
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Update the temporary filters
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setTempFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleApplyFilters = () => {
    setFilters(tempFilters); // Apply the tempFilters to main filters
    const searchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(tempFilters)) {
      if (value) searchParams.set(key, value);
    }
    navigate(`?${searchParams.toString()}`);
    setCurrentPage(1);
  };

  return (
    <div className="container mx-auto px-4 py-12 grid grid-cols-1 md:grid-cols-4 gap-6">
      {/* Filter Form */}
      <div className="col-span-1 bg-white rounded-lg shadow-md p-4 mt-8">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">
          Change your filter options
        </h2>
        <form className="space-y-4 ">
          {/* Price Range */}
          <div>
            <label
              htmlFor="price"
              className="block text-sm font-medium text-gray-700"
            >
              Maximum price
            </label>
            <input
              type="text"
              name="price"
              id="price"
              value={tempFilters.price}
              onChange={handleFilterChange}
              placeholder="Enter Max. Price"
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>

          {/* Property Type */}
          <div>
            <label
              htmlFor="type"
              className="block text-sm font-medium text-gray-700"
            >
              Property Type
            </label>
            <select
              name="type"
              id="type"
              value={tempFilters.type}
              onChange={handleFilterChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">Select Type</option>
              {[
                "Apartment",
                "Condo",
                "Land",
                "House",
                "Office & Shop",
                "Residence Villa",
                "Real Estate",
                "Commercial Area",
                "Commercial Building",
                "Warehouse",
                "Factory/Industry",
                "Guest House",
                "Other",
              ].map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          {/* City */}
          <div>
            <label
              htmlFor="city"
              className="block text-sm font-medium text-gray-700"
            >
              City
            </label>
            <select
              name="city"
              id="city"
              value={tempFilters.city}
              onChange={handleFilterChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">Select City</option>
              {cities?.data?.map((city) => (
                <option key={city._id} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>

          {/* Area */}
          <div>
            <label
              htmlFor="area"
              className="block text-sm font-medium text-gray-700"
            >
              Area
            </label>
            <select
              name="area"
              id="area"
              value={tempFilters.area}
              onChange={handleFilterChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              disabled={!areas.length}
            >
              <option value="">Select Area</option>
              {areas.map((area, index) => (
                <option key={index} value={area}>
                  {area}
                </option>
              ))}
            </select>
          </div>

          {/* Status */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Status
            </label>
            <div className="flex items-center gap-4">
              <label>
                <input
                  type="radio"
                  name="propertyStatus"
                  value="For Sale"
                  checked={tempFilters.propertyStatus === "For Sale"}
                  onChange={handleFilterChange}
                />
                <span className="ml-2">For Sale</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="propertyStatus"
                  value="For Rent"
                  checked={tempFilters.propertyStatus === "For Rent"}
                  onChange={handleFilterChange}
                />
                <span className="ml-2">For Rent</span>
              </label>
            </div>
          </div>

          <button
            type="button"
            onClick={handleApplyFilters}
            className="w-full bg-black text-white py-2 rounded-lg"
          >
            Apply Filters
          </button>
        </form>
      </div>

      {/* Property Listings */}
      <div className="col-span-1 md:col-span-3">
        {isLoading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {Array(6)
              .fill(null)
              .map((_, index) => (
                <PropertyCardSkeleton key={index} />
              ))}
          </div>
        ) : error ? (
          <div className="text-center text-red-500">
            Error fetching properties.
          </div>
        ) : (
          <>
            <PropertyListings properties={properties} />
            {/* ===pagination === */}
            {/* <div className="flex justify-between mt-6">
              <button
                onClick={prevPage}
                disabled={currentPage === 1}
                className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
              >
                Previous
              </button>
              <span className="self-center text-lg font-medium">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={nextPage}
                disabled={currentPage === totalPages}
                className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
              >
                Next
              </button>
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default FilteredProperty;
