const InputField = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  type = "text",
}) => {
  return (
    <div className="grid gap-2">
      <label className="text-sm font-semibold">{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className="w-full border-gray-300 rounded-md shadow-sm p-2 focus:ring focus:ring-blue-300"
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputField;
